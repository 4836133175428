<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay no-wrap :show="showOverlay" />
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img
          :src="heroleadsLogo"
          alt="Heroleads Logo"
        />
        <h2 class="brand-text text-primary ml-1 mt-2">
          HeroAIX
        </h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imageUrl" alt="image" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>
          <validation-observer ref="observer">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Send Reset Link
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'heroai-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back To Login
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  email as emailRule,
} from '@validations'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // validation
      required,
      emailRule,

      email: '',
      heroleadsLogo: require('@/assets/images/logo/heroleads-logo.png'),
      image: require('@/assets/images/pages/forgot-password-v2.svg'),
    }
  },
  computed: {
    imageUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.image = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.image
      }
      return this.image
    },
  },
  mounted() {
    this.$store.commit('heroAiAuthentications/doLogout')
    this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])
  },
  methods: {
    async validationForm() {
      let alertResult

      const axiosConfig = AxiosConfig.getJsonWithoutToken()
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to reset password?' })

        if (alertResult.value) {
          this.showOverlay = true

          const body = {
            email: this.email,
          }

          try {
            await axios.post('password/createResetLink', body, axiosConfig)

            alertResult = await this.$swal({ ...SweetAlert.success, text: "We've sent a password reset link to your email address" })

            if (alertResult.value) {
              await this.$router.push({ name: 'heroai-login' })
            }
          } catch (error) {
            await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
          }

          this.showOverlay = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
